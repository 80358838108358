import {
  Box,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import { navigate, PageProps } from 'gatsby'
import { toast } from 'react-toastify'
import { useEffect, useRef, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useGetAssociationsQuery } from 'manage-tritag/services/api/endpoints/associations'
import {
  Address,
  useDeleteVenueMutation,
  useGetVenueQuery,
  useUpdateVenueMutation,
  Venue,
} from 'manage-tritag/services/api/endpoints/venues'
import LocationPicker from 'manage-tritag/components/location-picker'
import DeleteModal from 'manage-tritag/components/modal/delete_modal'
import { noLimitParams } from 'manage-tritag/utils/string'
import PDFDownload from 'manage-tritag/components/pdf-download'
import Spinner from 'manage-tritag/components/loading/spinner'
import uploadImage from '../../../images/upload.png'
import {
  StyledGridContainer,
  UploadContainer,
  FormContent,
  StyledButtonDiv,
  StyledButton,
  StyledTextField,
} from './styles'

const VenueEditForm = (props: PageProps) => {
  const { params } = props

  const { data: associations, isLoading } =
    useGetAssociationsQuery(noLimitParams)
  const [updateVenue, { isLoading: updateLoading }] = useUpdateVenueMutation()
  const [deleteVenue] = useDeleteVenueMutation()
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      name: '',
      association: '',
      address: '',
      fields: '',
    },
  })
  const [address, setAddress] = useState<Address | undefined>(undefined)
  const { data: venueData, isLoading: venueLoading } = useGetVenueQuery(
    params?.id,
  )
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const [modal, setModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)

  useEffect(() => {
    if (venueData) {
      reset({
        name: venueData.name,
        association: venueData.association ? venueData.association._id : '',
        fields: venueData.fields.toString(),
      })
    }
  }, [reset, venueData])

  const handleDelete = async () => {
    setModal(false)
    await deleteVenue(params?.id).unwrap()
    toast.success('Venue deleted succesfully.')
    navigate('/venues')
  }

  const handleFileClick = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click()
    }
  }

  const handleFileChange = (e: any) => {
    setSelectedFile(e.target.files[0])
  }

  const onSubmit: SubmitHandler<Venue> = async (createData: any) => {
    if (updateLoading) return
    const fileData = new FormData()
    const reqData = {
      id: params?.id,
      name: createData.name,
      association: createData.association,
      fields: createData.fields,
      address,
    }
    fileData.append('formData', JSON.stringify(reqData))
    fileData.append('file', selectedFile!)

    await updateVenue(fileData).unwrap()
    reset()
    toast.success('Venue updated succesfully.')
    navigate('/venues')
  }

  if (venueLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 145px);',
        }}
      >
        <Spinner size={40} color="#008174" />
      </Box>
    )

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <StyledGridContainer container spacing={3} justifyContent="space-between">
        <Grid item xs={12} sm={12} md={3}>
          <UploadContainer onClick={handleFileClick}>
            <Typography
              variant="subtitle2"
              component="p"
              style={{ marginBottom: '0.8rem', fontWeight: 'bold' }}
            >
              Upload your field map
            </Typography>
            <Typography variant="subtitle2" style={{ fontSize: '0.8rem' }}>
              PDF file
            </Typography>
            <Typography
              variant="subtitle2"
              component="h6"
              style={{ fontSize: '0.8rem' }}
            >
              format only
            </Typography>

            <img
              height="50"
              src={uploadImage}
              alt=""
              style={{ marginTop: '1rem' }}
            />
          </UploadContainer>

          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={8}>
          <Typography style={{ fontWeight: 'bold', color: '#008174' }}>
            Venue details
          </Typography>

          <FormContent>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 1 }}
                  id="filled-basic"
                  label="Name"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            <FormControl variant="filled" size="small" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                Association *
              </InputLabel>
              <Controller
                name="association"
                control={control}
                render={({ field }) => (
                  <Select
                    inputProps={{ tabIndex: 2 }}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    disableUnderline
                    required
                    {...field}
                  >
                    {!isLoading &&
                      associations &&
                      associations.map(association => (
                        <MenuItem key={association._id} value={association._id}>
                          {association.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
          </FormContent>

          <FormContent>
            <LocationPicker
              tabIndex={3}
              setAddress={setAddress}
              defaultValue={venueData?.address}
            />

            <Controller
              name="fields"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 4 }}
                  id="filled-basic"
                  label="Number of fields"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />
          </FormContent>
        </Grid>
      </StyledGridContainer>

      {venueData && venueData.fieldMap && venueData.fieldMap !== '' ? (
        <Box sx={{ marginBottom: '3rem' }}>
          <Grid item xs={12} sm={12} md={4}>
            <PDFDownload url={venueData.fieldMap} />
          </Grid>
          <Grid item xs={12} sm={12} md={3} />
          <Grid item xs={12} sm={12} md={4} />
        </Box>
      ) : (
        <Box sx={{ marginBottom: '1rem' }} />
      )}

      <StyledButtonDiv>
        <StyledButton
          variant="contained"
          onClick={() => {
            setModal(true)
          }}
          style={{
            backgroundColor: 'red',
            color: '#fff',
          }}
        >
          DELETE
        </StyledButton>

        <div>
          <StyledButton
            variant="outlined"
            onClick={() => {
              navigate('/venues')
            }}
            style={{
              border: '2px solid #008174',
              color: '#000',
              marginRight: '1.5rem',
            }}
          >
            CANCEL
          </StyledButton>
          <StyledButton
            variant="contained"
            color="primary"
            type="submit"
            style={{ width: '83px' }}
          >
            {updateLoading ? <Spinner size={22} /> : 'SAVE'}
          </StyledButton>
        </div>
      </StyledButtonDiv>
      {modal && (
        <DeleteModal
          setModal={setModal}
          label="venue"
          handleDelete={handleDelete}
        />
      )}
    </Box>
  )
}

export default VenueEditForm
