import { Grid, Box, Menu, MenuItem } from '@mui/material'
import { useState } from 'react'
import axios from 'axios'
import FileIcon from '../icons/file'
import SpreadIcon from '../icons/spread'
import { StyledItem } from '../pages/venues/styles'
import Spinner from '../loading/spinner'

interface PDFDownloadProps {
  url: string
}

const PDFDownload = ({ url }: PDFDownloadProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDownload = async () => {
    setIsLoading(true)
    const id = url.split('.com/')[1]
    await axios
      .post(
        'https://api.tritagrugby.com/venues/download-file',
        {
          url: id,
        },
        {
          responseType: 'blob',
        },
      )
      .then(response => {
        const pdfUrl = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = pdfUrl
        link.setAttribute('download', `field-map.pdf`)
        document.body.appendChild(link)
        link.click()
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  return (
    <Box>
      <StyledItem>
        <Grid container>
          <Grid item xs={1} sm={1} md={2}>
            <FileIcon />
          </Grid>
          <Grid item xs={11} sm={11} md={8}>
            <Box>
              <div>field-map.pdf</div>
            </Box>
          </Grid>
          <Grid item xs={1} sm={1} md={2} sx={{ cursor: 'pointer' }}>
            <Box onClick={handleClick}>
              <SpreadIcon />
            </Box>
          </Grid>
        </Grid>
      </StyledItem>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {isLoading ? (
          <MenuItem>
            <Box sx={{ margin: 'auto' }}>
              <Spinner size={25} color="#008174" />
            </Box>
          </MenuItem>
        ) : (
          <MenuItem onClick={handleDownload}>Download</MenuItem>
        )}
        <MenuItem>Remove</MenuItem>
      </Menu>
    </Box>
  )
}

export default PDFDownload
