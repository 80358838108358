import { withAuthenticationRequired } from '@auth0/auth0-react'
import { PageProps } from 'gatsby'
import Layout from 'manage-tritag/components/layout'
import VenueEditForm from 'manage-tritag/components/pages/venues/edit-form'

const VenueEditPage = (props: PageProps) => {
  return (
    <Layout label1="Venues" label2="Update" url="/venues">
      <VenueEditForm {...props} />
    </Layout>
  )
}

export default withAuthenticationRequired(VenueEditPage)
