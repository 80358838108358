import { Button, Grid, styled, TextField } from '@mui/material'

export const Container = styled('div')`
  display: flex;
  justify-content: space-between;
  column-gap: 4rem;
`
export const StyledGridContainer = styled(Grid)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(900)]: {
        marginBottom: '3rem !important',
      },
    }
  }}
  margin-bottom: 3rem !important
`
export const UploadContainer = styled('div')`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(900)]: {
        margin: 'auto',
      },
    }
  }}
  border-radius: 10px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  max-width: 190px;
  max-height: 200;
  padding: 2rem 0rem 1.4rem 0rem;
  margin-bottom: 2.5rem;
  text-align: center;
`
export const FormContent = styled('div')`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(900)]: {
        flexDirection: 'column',
        rowGap: '2.5rem',
      },
    }
  }}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 1.5rem;
  margin-top: 2.5rem;
`
export const SecondFormContent = styled('div')`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(900)]: {
        marginTop: '1rem',
      },
    }
  }}
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
`

export const StyledButtonDiv = styled('div')`
  display: flex;
  justify-content: space-between;
`

export const StyledButton = styled(Button)`
  font-size: 0.8rem;
`

export const StyledItem = styled('div')`
  ${({ theme }) => {
    return {
      [theme.breakpoints.up(900)]: {
        width: '90%',
      },
    }
  }}
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  color: #000;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin: 0px 0px 8px 0px;
  padding: 0.5rem;
  height: 40px;
  border-radius: 0.4rem;
`
export const SpaceDivider = styled('div')`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(900)]: {
        display: 'none !important',
      },
    }
  }}
  opacity: 0;
`
export const StyledTextField = styled(TextField)`
  width: 100%;
`
